import React, { FC } from 'react'
import { BreadcrumbJsonLd, NextSeo } from 'next-seo'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { TypeMappingNewsOutput } from '@shared/api/middleware/mappingAPI/news/type'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'
import { CarouselRestaurantsJsonLd } from '@shared/lib/components/CarouselRestaurantsJsonLd'
// @ts-ignore
import defaultSEOImg from '@images/SEO/social-img-default.png'

interface Props {
  seoData: any
  globalData: any
  homeData: {
    home_rests: {
      special_offer: TypeMappingRestOutput[]
      by_rating: TypeMappingRestOutput[]
    }
    home_news: {
      all_data: TypeMappingNewsOutput[]
      interviu: TypeMappingNewsOutput[]
      plani_na_nedelu: TypeMappingNewsOutput | null
    }
    afisha_selected: TypeMappingSelectionOutput[]
  }
}

const AppHomeSEO: FC<Props> = ({ seoData, globalData, homeData }) => {
  // SEO: https://www.afisha.ru/rests/api/public/v1/seo/index/?city_id=2
  const afishaNameSEO = 'Афиша Рестораны'
  // USED: title
  // USED: description
  // USED: canonical
  // USED: is_noindex_nofollow
  // USED: content_type
  // USED: keywords
  // TODO: not-used — seoData.amp_link: ""
  // TODO: not-used — seoData.image_choice_pic: null
  // TODO: not-used — seoData.image_static: ""
  // TODO: not-used — seoData.image_title: "Ресторан;wtest;на улице Атлантический океан"
  // TODO: not-used — seoData.image_type: "restaurant"
  // TODO: not-used — seoData.image_url: "https://s5.afisha.ru/mediastorage/17/6a/955da8d3091c46288913e8a06a17.jpg"
  // TODO: not-used — seoData.ios_url: "restaurant/48447"
  // TODO: not-used — seoData.next_link: ""
  // TODO: not-used — seoData.prev_link: ""
  // TODO: not-used — seoData.request: ""

  const additionalMetaTags = [
    {
      property: 'og:description',
      content: seoData.description ? seoData.description : null,
    },
    {
      property: 'og:type',
      content: seoData.content_type ? seoData.content_type : null,
    },
    {
      property: 'og:locale',
      content: `ru_RU`,
    },
    {
      property: 'og:site_name',
      content: `${process.env.AFISHA_NAME}`,
    },
    {
      name: 'keywords',
      content: seoData.keywords ? `${seoData.keywords}` : null,
    },
    {
      name: 'apple-mobile-web-app-title',
      content: `${process.env.AFISHA_NAME}`,
    },
    {
      name: 'application-name',
      content: `${process.env.AFISHA_NAME}`,
    },
    {
      httpEquiv: 'Content-Type',
      content: 'text/html; charset=utf-8',
    },
  ]

  const featureList = globalData.city_read.feature_restaurants || []
  const ratingList = homeData?.home_rests?.by_rating || []
  const specialOfferList = homeData?.home_rests?.special_offer || []

  return (
    <>
      <CarouselRestaurantsJsonLd
        restaurants={[...featureList, ...ratingList, ...specialOfferList]}
        cityList={globalData.city_list}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: `Рестораны ${globalData.city_read.genitive_case}`,
            item: seoData.canonical,
          },
        ]}
      />

      <NextSeo
        title={seoData.title || null}
        description={seoData.description || null}
        canonical={seoData.canonical || null}
        themeColor={'#ffffff'}
        noindex={seoData.is_noindex_nofollow || false}
        nofollow={seoData.is_noindex_nofollow || false}
        robotsProps={null}
        openGraph={{
          title: seoData.title,
          description: seoData.description,
          url: seoData.canonical,
          images: [{ url: `https://www.afisha.ru${defaultSEOImg.src}` }],
          type: 'website',
          locale: 'ru_RU',
        }}
        facebook={null}
        twitter={null}
        // @ts-ignore
        additionalMetaTags={additionalMetaTags || null}
        titleTemplate={null}
        defaultTitle={seoData.title || null}
        mobileAlternate={null}
        languageAlternates={null}
      />
    </>
  )
}

export default AppHomeSEO
